import { render, staticRenderFns } from "./TheDialog.vue?vue&type=template&id=315f51b9&scoped=true&"
import script from "./TheDialog.vue?vue&type=script&lang=js&"
export * from "./TheDialog.vue?vue&type=script&lang=js&"
import style0 from "./TheDialog.vue?vue&type=style&index=0&id=315f51b9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "315f51b9",
  null
  
)

export default component.exports