import request from "@/lib/axios";
/*
用法
plugins: App.Plugin[] = [];
const {
  data: { data: list }
} = await Api.pluginDetail({
  shop_url: "https://customtest1.myshopify.com"
});
this.plugins = list;
// pluginDetail: (params: { plugin_id: string }) => request.get("/api/shopify/plugin-detail", params ),
*/
export default {
  challengeDetail: (cid: string, token: string, lang: string) => {
    return request.get(
      `api/challenge/detail?challenge_id=${cid}&token=${token}&language=${lang}`
    );
  },

  userInfo: (code: string) => {
    return request.get(`api/user/code/${code}/detail `);
  },
};

export const defaultDetail: Grow.Detail = {
  id: 0,
  name: "",
  cover: "",
  startTime: 0,
  endTime: 0,
  webUrl: "",
  attends: 0,
  description: "",
  achCondition: "",
  badge: {
    name: "",
    image: "",
    description: "",
  },
  attenders: [],
  attended: false,
};

export const defaultInvitor: Grow.Invitor = {
  uid: 0,
  token: "",
  nick: "",
  avatar:
    "https://growapp.oss-cn-hangzhou.aliyuncs.com/system/default%20avatar%403x.png",
  vip: false,
};
