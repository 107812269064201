import axios from "axios";
// 语言转换
const langCode = {
  "zh-CN": "cmn-hans",
  "zh-TW": "cmn-hant",
  "en-US": "en",
};

// 全局 axios 实例
const instance = axios.create({
  // baseURL: process.env.VUE_APP_HOST
  baseURL: "https://api.floatingislandapps.com/",
});

// 超时限制
instance.defaults.timeout = 100000;

instance.interceptors.request.use(
  (config) => {
    // const { headers } = config;
    // const lang = session.get<string>('language') || 'en-US';
    // if (userData.token.access_token) {
    //   headers['Authorization'] = `Bearer ${userData.token.access_token}`;
    // }
    // headers['Authorization'] = `Bearer ${userData.token.access_token}`;
    // headers['ACCEPT-LANGUAGE'] = langCode[lang];
    // headers['access-token'] = `${userData.token.access_token}`;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (response) => {
    // TODO handle data & status
    // console.log('response', response);
    return response;
  },
  (err: any) => {
    const errorMsg = err.response.data.message;
    console.error("response", errorMsg);
    return Promise.reject(err);
  }
);

export default instance;
