import Vue from "vue";
import App from "./App.vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// import FastClick from "fastclick";

// FastClick.attach(document.body);

const i18n = new VueI18n({
  locale: "ja",
  messages: {},
});

new Vue({
  i18n,
  render: (h) => h(App),
}).$mount("#app");
