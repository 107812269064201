























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import GrowAppBar from "@/components/GrowAppBar.vue";
import OpenInSafari from "@/components/OpenInSafari.vue";
import TheDialog from "@/components/TheDialog.vue";
import { defaultDetail, defaultInvitor } from "@/lib/api";
import Api from "@/lib/api";
import dateFormat from "dateformat";

@Component({
  components: { GrowAppBar, TheDialog, OpenInSafari },
})
export default class Preview extends Vue {
  contentLoaded = false;
  detail = defaultDetail;
  invitor = defaultInvitor;
  lang = "cn";
  code = "";
  cid = "";

  universalLinks = "grow://invite";
  async fetchList() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.code = params.code as string;
    this.cid = params.cid as string;
    const lang: string = params.lang as string;
    this.$i18n.locale = lang;
    this.lang = lang;

    try {
      const {
        data: { data: invitor },
      } = await Api.userInfo(this.code);
      this.invitor = invitor;
    } catch (error) {
      console.error(error);
    }

    const {
      data: { data: detail },
    } = await Api.challengeDetail(this.cid, this.code, lang);
    this.detail = detail;
    this.contentLoaded = true;
    if (this.isIOS) {
      this.openApp(this.openLink);
    }
  }

  get openLink() {
    return this.universalLinks + `?code=${this.code}&cid=${this.cid}`;
  }

  get hasInvitor() {
    return this.invitor !== null;
  }

  get isIOS() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  get vailedAvatars() {
    const mock: Grow.Attender = {
      id: -100,
      nick: "",
      avatar:
        "https://growapp.oss-cn-hangzhou.aliyuncs.com/system/default%20avatar%403x.png",
      vip: 0,
    };
    const avatars = ["", "", "", "", "", "", "", "", "", ""].map(() => mock);
    const filtered = this.detail.attenders.filter(
      (item) => item.avatar !== null
    );
    return filtered
      .concat(avatars)
      .slice(0, Math.min(this.isSmallScreen ? 8 : 10, this.detail.attends));
  }

  get displayTime() {
    const start = new Date(this.detail.startTime);
    const end = new Date(this.detail.endTime);
    const member = this.detail.attends;

    const diffTime = Math.abs(this.detail.endTime - this.detail.startTime);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return (
      dateFormat(start, "mmm dd") +
      " - " +
      dateFormat(end, "mmm dd") +
      " · " +
      diffDays +
      " " +
      this.$t("days")
    );
  }

  get isSmallScreen() {
    return window.screen.width <= 320;
  }

  mounted() {
    this.fetchList();
  }

  get isBlackApp() {
    const u = navigator.userAgent.toLowerCase();
    return (
      /micromessenger/i.test(u) ||
      u.indexOf("weibo") > -1 ||
      u.indexOf("qq") > -1 ||
      u.indexOf("mqqbrowser") > -1
    );
  }

  openApp(href: string) {
    try {
      window.location.href = href;
    } catch (e) {
      window.location.href = "https://itunes.apple.com/app/id1560604814?mt=8#";
      console.error(e);
    }
  }

  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
}
