




import HomePage from "./views/HomePage.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    HomePage,
  },
})
export default class App extends Vue {
  mounted() {
    // 手动实现的基于750像素设计图rem布局
    const doc = document.documentElement || document.body;
    const docH = doc.getBoundingClientRect().width / 7.5;
    doc.style.fontSize = docH + "px";
  }
}
