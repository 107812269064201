




























import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Preview extends Vue {
  @Prop({ default: "cn" }) readonly lang!: string;

  mounted() {
    this.$i18n.locale = this.lang;
  }
}
